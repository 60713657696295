/*Login Styles*/
/*Login Styles*/

.login-container {
  position: relative;
  height: 100%;
}

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 30px;
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;

  @media screen and (max-width: 575px) {
    padding: 25px 25px 20px;
  }
}

.login-header {
  margin-bottom: 30px;

  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
}

.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-login-container {
  position: relative;
  width: 100%;
  margin: 0 auto;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.app-login-main-content {
  @include display-flex();
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(0);
  font-size: 14px;
  overflow: hidden;
  width:100%;
  height:100%;
  color:rgba($app-primary, 0.5);

  & a{
    color:#324FAD;
  }
}

.app-login-content {
  position: relative;
  padding:0;
  width: 40%;
  order: 2;

  @media screen and (max-width: 768px) {
    width: 100%;
    order: 2;
  }
  & .MuiInputBase-input{
    &:-webkit-autofill{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
  & .terms{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    @media screen and (max-width:768px) {
      padding:0 30px;
    }
  }
}

.app-login-header {
  margin-bottom: 30px;
  h1{
    font-size:36px;
    color:$app-primary;
    @media screen and (max-width:768px) {
      font-size:30px;
      & br{
        display:none
      }
    }
  }
  h2{
    font-size:24px;
    color:$app-primary;
  }
}
.login-form-wrapper{
  @media screen and (min-width:768px) {
    position: absolute;
    top:20%;
    left: 40%;
    transform: translate(-38%, -20%);
    padding:0;
    width:70%;
  }

  @media screen and (max-width:768px) {
    padding:30px;
  }

  & .logo-lg{
    margin-bottom:30%;
    display: inline-block;
    @media screen and (max-width:768px) {
      margin-bottom:20%;
      & img{
        max-width: 130px;
      }
    }
  }
.button-wrapper{
  @media screen and (max-width:768px) {
    display:block!important;
    text-align: center;
  }
  & button{
    min-width: 153px;
    min-height: 40px;
    @media screen and (max-width:768px) {
      display:block;
      width:100%;
      margin:18px 0 10px;
    }
  }
}

}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }

}

.app-login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  background-image:url(../../assets/images/stayhopper-extranet.jpg);
  background-repeat:no-repeat;
  background-size: cover;
  background-color: $app-primary;
  padding: 35px 35px 20px;
  width: 60%;
  order: 1;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 1;
  }
  @media screen and (max-width:768px) {
    display:none!important;
  }
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}

.login-form {
  form > :last-child {
    margin-bottom: 0;
  }
}
