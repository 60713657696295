.has-richtext {
  h1, h2, h3, h4, h5, h6 {
    margin: 15px 0;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.3em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.8em;
  }

  h6 {
    font-size: 0.7em;
  }


  ol, ul {
    margin: 10px 20px;
  }
}