.field-view-image {
  min-height: 48px;
}

.field-view-value:empty:before {
  content: 'N/A';
  color: #999;
}

.availability-has-sticky-header {
  padding-top: 56px !important;
}

.availability-header-sticky {
  position: absolute;
  z-index: 1;
}