/*Theme Dark Deep Orange Styles*/
$app-primary-dark-deep-orange: #FF4848 !default;
$secondary-dark-deep-orange: #1A1E66;
$secondary-dark-deep-blue: #23BCF9;
$sh-yellow:#FFC764;



$header-dark-deep-orange: #0A174C !default;
$sidebar-dark-deep-orange: $white !default;
$sidebar-text-dark-deep-orange: #666666 !default;
$sidebar-darken-deep-orange: darken($sidebar-dark-deep-orange, 3%);
$sidebar-bg-darken-highlight-deep-orange: $app-primary-dark-deep-orange;


// Links
$link-hover-dark-deep-orange: $white;

//-Base-scss
.dark-deep-orange .right-arrow {
  color: $app-primary-dark-deep-orange;
  &:after {
    color: $app-primary-dark-deep-orange;
  }
}



//- Bootstrap file Style
.dark-deep-orange {
  a,
  .card-link,
  .jr-link,
  .jr-link.text-primary {
    color: $secondary-dark-deep-orange;
    &:focus,
    &:hover {
      color: darken($secondary-dark-deep-orange, 10%);
    }
  }
  
  .MuiFormLabel-root{
    color:$header-dark-deep-orange;
  }

  & .text-primary {
    color: $app-primary-dark-deep-orange !important;
  }

  & .page-link {
    color: $app-primary-dark-deep-orange;
    &:focus,
    &:hover {
      color: $app-primary-dark-deep-orange;
    }
  }

  & .page-heading .breadcrumb-item.active {
    color: $app-primary-dark-deep-orange;
  }
  & .page-heading .title{
    font-weight: 300;
    z-index: 9;
    font-size: 30px;
    top: 12px;
    position: relative;
    color:$header-dark-deep-orange;
    @media screen and (max-width: 575px) {
      display:none;
    }
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-dark-deep-orange !important;
    color: $link-hover-dark-deep-orange !important;
  }

  & .btn-primary {
    background-color: $app-primary-dark-deep-orange;
    border-color: $app-primary-dark-deep-orange;
    color: $link-hover-dark-deep-orange;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-dark-deep-orange, 5%) !important;
      border-color: darken($app-primary-dark-deep-orange, 5%) !important;
      color: $link-hover-dark-deep-orange !important;
    }
  }

  & .bg-lighter-blue{
    background-color: $sh-lighter-blue !important;
    color: $secondary-dark-deep-orange !important;
  }
  & .bg-lighter-blue-dark{
    background-color: $sh-lighter-blue-dark !important;
    color: $secondary-dark-deep-orange !important;
  }
  & .bg-lighter-yellow{
    background-color: $sh-lighter-yellow !important;
    color: $secondary-dark-deep-orange !important;
  }
  & .bg-lighter-green{
    background-color: $sh-lighter-green !important;
    color: $secondary-dark-deep-orange !important;
  }
  & .bg-lighter-orange{
    background-color: $sh-lighter-orange !important;
    color: $secondary-dark-deep-orange !important;
  }
  & .bg-lighter-red{
    background-color: $sh-lighter-red !important;
    color: $secondary-dark-deep-orange !important;
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #ffccbc !important;
    }

    &.lighten-3 {
      background-color: #ffab91 !important;
    }

    &.lighten-2 {
      background-color: #ff8a65 !important;
    }

    &.lighten-1 {
      background-color: #ff7043 !important;
    }

    &.darken-1 {
      background-color: #f4511e !important;
    }

    &.darken-2 {
      background-color: #e64a19 !important;
    }

    &.darken-3 {
      background-color: #d84315 !important;
    }

    &.darken-4 {
      background-color: #bf360c !important;
    }

    &.accent-1 {
      background-color: #ff9e80 !important;
    }

    &.accent-2 {
      background-color: #ff6e40 !important;
    }

    &.accent-3 {
      background-color: #ff3d00 !important;
    }

    &.accent-4 {
      background-color: #dd2c00 !important;
    }
  }
}

//Secondary
.dark-deep-orange {
  .jr-link.text-secondary {
    color: $secondary-dark-deep-orange;
    &:focus,
    &:hover {
      color: darken($secondary-dark-deep-orange, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-dark-deep-orange !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-dark-deep-orange !important;
    color: $link-hover-dark-deep-orange !important;
  }

  & .btn-secondary {
    background-color: $secondary-dark-deep-orange;
    border-color: $secondary-dark-deep-orange;
    color: $link-hover-dark-deep-orange;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-dark-deep-orange, 5%) !important;
      border-color: darken($secondary-dark-deep-orange, 5%) !important;
      color: $link-hover-dark-deep-orange !important;
    }
  }
}

//_header.scss
.dark-deep-orange .app-main-header {
  // background-color: $app-primary-dark-deep-orange !important;
  background-color: $header-dark-deep-orange !important;

  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
  & .user-avatar{
    font-size:14px;
    @media screen and (max-width: 575px) {
      height: 30px !important;
      width: 30px !important;
      font-size:13px;
    }
  }
}

//_right-sidebar.scss
.dark-deep-orange .color-theme-header {
  background-color: $app-primary-dark-deep-orange;

}

//_sidebar.scss
.dark-deep-orange .side-nav {
  background-color: $sidebar-dark-deep-orange !important;
  color: $sidebar-text-dark-deep-orange !important;
  @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));

  & .user-profile {
    background-color: $sidebar-darken-deep-orange;
    @include box-shadow(none);
    position: relative;
    z-index: 2;
  }

  & .customizer {
    border-bottom: 0 none;
  }

  & .user-detail {
    & .user-name {
      color: $link-hover-dark-deep-orange;
    }
  }
}

.dark-deep-orange .nav-section {

  & .nav-header {
    color: $sidebar-text-dark-deep-orange;
  }
}

.dark-deep-orange .nav-collapse {

  & .nav-collapse-btn {
    color: $sidebar-text-dark-deep-orange;
    font-size:14px;
    font-weight:400;
    background-color:transparent;
    &:focus,
    &:hover {
      background-color:rgba($secondary-dark-deep-orange, 0.05);
      color:$sidebar-text-dark-deep-orange;

    }
  }

  &.open {
    background-color: $sidebar-darken-deep-orange;

    & .nav-collapse-btn {
      background-color:rgba($secondary-dark-deep-orange, 0.02);
      color: $secondary-dark-deep-orange;

      &:focus,&:hover
      {
        background-color:transparent;
        color: $secondary-dark-deep-orange;
      }
    }
  }
}

.dark-deep-orange .nav-collapse {
  & .nav-menu-item .nav-menu-link {
    color: $sidebar-text-dark-deep-orange;

    &:focus,
    &:hover,
    &.active {
      background-color:rgba($secondary-dark-deep-orange, 0.2);
      color: $secondary-dark-deep-orange;
    }

    &:after {
      background-color:transparent;
    }
  }

  &.open {
    & .nav-menu-item .nav-menu-link.active {
      background-color:rgba($app-primary-dark-deep-orange, 0.2);
      color: $app-primary-dark-deep-orange;
      font-weight: 500;
    }
  }
}

.dark-deep-orange .nav-menu-item {
  & .nav-menu-link {

    &:focus,
    &:hover {
      background-color:rgba($secondary-dark-deep-orange, 0.2);
      color: $secondary-dark-deep-orange;
    }
    &.active {
      background-color:rgba($app-primary-dark-deep-orange, 0.2);
      color: $app-primary-dark-deep-orange;
      font-weight: 500;
    }
  }
}

/*Header top Navbar Styles*/
.dark-deep-orange .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $app-primary-dark-deep-orange;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $app-primary-dark-deep-orange;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-dark-deep-orange;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $app-primary-dark-deep-orange;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $app-primary-dark-deep-orange;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $app-primary-dark-deep-orange;
    }
  }
}

.dark-deep-orange .app-top-nav {

  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-dark-deep-orange;
    }
  }
}

.dark-deep-orange .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

.dark-deep-orange .header-notifications .app-notification {
  & .jr-list-link {

    @include hover-focus-active {
      color: $app-primary-dark-deep-orange;
      border-color: $app-primary-dark-deep-orange;
    }
  }
}

//_app-module.scss
.dark-deep-orange .module-nav {
  & li {
    & a.active {
      border-color: $app-primary-dark-deep-orange;
    }

    & .jr-link.active {
      color: $app-primary-dark-deep-orange;
    }
  }
}

//_calendar.scss
.dark-deep-orange .rbc-event {
  background-color: $secondary-dark-deep-orange;
}

.dark-deep-orange .rbc-off-range-bg {
  opacity: 0;
}
.dark-deep-orange .rbc-off-range {
  opacity: 0.5;
}

.dark-deep-orange .rbc-day-bg {
  background-color: #f8f8fb;
}

.dark-deep-orange .rbc-day-bg:hover {
  background-color: #cfcffd !important;
}

.dark-deep-orange .rbc-selected-cell {
  background-color: #bdbdff !important;
}

.dark-deep-orange .rbc-today {
  background: #ddeedd;
}

.dark-deep-orange .rbc-time-view .rbc-today {
  background: transparent;
}

.dark-deep-orange .rbc-event.rbc-selected {
  background-color: darken($secondary-dark-deep-orange, 10%);
}

.dark-deep-orange .rbc-slot-selection {
  background-color: rgba($secondary-dark-deep-orange, 0.7);
}

.dark-deep-orange .rbc-toolbar button:active,
.dark-deep-orange .rbc-toolbar button.rbc-active {
  background-color: rgba($secondary-dark-deep-orange, 0.9);
  border-color: $secondary-dark-deep-orange;
}

.dark-deep-orange .rbc-toolbar button:active:hover,
.dark-deep-orange .rbc-toolbar button.rbc-active:hover,
.dark-deep-orange .rbc-toolbar button:active:focus,
.dark-deep-orange .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($secondary-dark-deep-orange, 0.9);
  border-color: $secondary-dark-deep-orange;
}

.dark-deep-orange .rbc-toolbar button:focus {
  background-color: rgba($secondary-dark-deep-orange, 0.9);
  border-color: $secondary-dark-deep-orange;
}

.dark-deep-orange .rbc-toolbar button:hover {
  background-color: rgba($secondary-dark-deep-orange, 0.9);
  border-color: $secondary-dark-deep-orange;
}

//_chat.scss
.dark-deep-orange .chat-sidenav-title {
  color: $app-primary-dark-deep-orange;
}

.dark-deep-orange .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-dark-deep-orange, 76%);
  }
}

//_dashboard.scss
.dark-deep-orange .contact-list {
  & i {
    color: $app-primary-dark-deep-orange;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.dark-deep-orange .Collapsible__trigger {
  background: $app-primary-dark-deep-orange;
}

.dark-deep-orange .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-dark-deep-orange;
  }
}

//_login.scss
.dark-deep-orange .login-content .form-control {
  &:focus {
    border-color: $app-primary-dark-deep-orange;
  }
}

//_portfolio.scss
.dark-deep-orange .filter-with-bg-color ul li {
  .jr-link {
    border-color: $app-primary-dark-deep-orange;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-dark-deep-orange;
    }
  }
}

//_card.scss
.dark-deep-orange .profile-intro {
  & .icon {
    color: $app-primary-dark-deep-orange;
  }
}

.dark-deep-orange .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-dark-deep-orange;
  }
}

//_tables.scss
.dark-deep-orange .actions {
  color: $secondary-dark-deep-orange;
}

.dark-deep-orange .table-hover tbody tr:hover {
  background-color: rgba($app-primary-dark-deep-orange, 0.075);
}

//Border Color

.dark-deep-orange .border-primary {
  border-color: $app-primary-dark-deep-orange !important;
}

// login page content

.dark-deep-orange .app-logo-content {
  background-color: $app-primary-dark-deep-orange;
}

.dark-deep-orange .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-dark-deep-orange;
    color: $app-primary-dark-deep-orange;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-dark-deep-orange;
    }
  }
}

/*Button Group Styles*/
.dark-deep-orange .btn-group,
.dark-deep-orange .btn-group-vertical {
  > .jr-btn {
    &.active {
      background-color: $app-primary-dark-deep-orange;
      border-color: $app-primary-dark-deep-orange;
    }

  }

  > .jr-flat-btn {
    background-color: transparent;
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $app-primary-dark-deep-orange;
      &:hover,
      &:focus {
        background-color: rgba($app-primary-dark-deep-orange, 0.12);
        color: $app-primary-dark-deep-orange;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.dark-deep-orange .jr-fillchart-btn-close,
.dark-deep-orange .jr-onchart .jr-badge-up,
.dark-deep-orange .jr-task-list-item:hover .jr-text-hover {
  color: $app-primary-dark-deep-orange;
}

.dark-deep-orange .jr-entry-title:before {
  background-color: $app-primary-dark-deep-orange;
}

.dark-deep-orange .jr-card-ticketlist {
  & .jr-task-list-item:hover .jr-task-item-title,
  .jr-link {
    color: $app-primary-dark-deep-orange;
  }
}

.dark-deep-orange .slick-dots li.slick-active button:before {
  border-color: $app-primary-dark-deep-orange;
}

//Nav Styles
.dark-deep-orange .nav-pills .nav-link.active,
.dark-deep-orange .nav-pills .show > .nav-link {
  color: $white !important;
  background-color: $app-primary-dark-deep-orange;
}

// Gradient Color Class
.dark-deep-orange .bg-gradient-primary {
  @include gradient-directional($app-primary-dark-deep-orange, lighten($app-primary-dark-deep-orange, 16%), 0deg);
}

.dark-deep-orange .bg-gradient-primary-x {
  @include gradient-x(darken($app-primary-dark-deep-orange, 10%), lighten($app-primary-dark-deep-orange, 16%), 70%, 100%);
}

//Profile style
.dark-deep-orange .jr-profile-banner {
  background-color: $app-primary-dark-deep-orange;
  color: $white;

  & .jr-link {
    color: $white;

    &:hover,
    &:focus {
      color: $secondary-dark-deep-orange;
    }
  }
}

.dark-deep-orange .dropdown-item {

  &.active,
  &:active {
    background-color: $app-primary-dark-deep-orange;
    color: $white;
  }

  &[class*="text-"] {

    &.active,
    &:active {
      background-color: transparent;
    }
  }
}


// Custom styles

.searchFilter{
  margin: 0 -20px 20px;
//  & > div {
  //  margin: 0 20px;
  //  &:after {
  //   content: "";
  //   height: 25px;
  //   display: block;
  //   margin: 19px -40px -15px;
  //   background:$body-bg;
  //   border-top: 1px solid $border-color;
  //  }
//  }
}

.propertyList {
  th{
   span{
     color:$headings-color;
     font-weight: 600;
    font-size: 11px!important;
    opacity: 1!important;
    line-height:14px;
   }
   & svg{
     font-size:14px;
     color:$headings-color!important;
     top: -1px;
    position: relative;
   }
  }
}